/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { graphql, navigate } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useState } from 'react'
import DOMPurify from 'dompurify'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2'
import SROnly from 'src/components/ui/SROnly'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import type { SearchState } from '@faststore/sdk'
import type { PageProps } from 'gatsby'
import type {
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables,
} from '@generated/graphql'
import Breadcrumb from 'src/components/ui/Breadcrumb'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { B2B } from 'src/store-config/B2B-Config'
import { B2C } from 'src/store-config/B2C-Config'
import axios from 'axios'

export type Props = PageProps<
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables
>

const useSearchParams = ({ href }: Location) => {
  const [params, setParams] = useState<SearchState | null>(null)

  useEffect(() => {
    const url = new URL(href)

    url.search = DOMPurify.sanitize(url.search)

    setParams(parseSearchState(url))
  }, [href])

  return params
}

export default function SearchPage(props: Props) {
  const searchParams = useSearchParams(props.location)

  const hasRedirect = !!(props as any)?.serverData?.redirect

  if (hasRedirect) {
    return navigate((props as any).serverData.redirect as string)
  }

  if (!props || hasRedirect) {
    return null
  }

  const {
    data: { site },
  } = props

  const title = 'Search Results | FastStore'
  const EVENT_API_BASE_PATH = 'https://sp.vtex.com'
  const isB2B = checkEnviromentIsB2B()

  if (!searchParams) {
    return null
  }

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <GatsbySeo
        language="pt-br"
        title={title}
        description={site?.siteMetadata?.description ?? ''}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
        linkTags={[
          {
            rel: 'preconnect',
            href: EVENT_API_BASE_PATH,
          } as any,
          {
            rel: 'preconnect',
            href: 'https://decathlonproqa.vteximg.com.br',
          } as any,
          {
            rel: 'preconnect',
            href: 'https://decathlonstore.vtexassets.com',
          } as any,
          {
            rel: 'dns-prefetch',
            href: EVENT_API_BASE_PATH,
          } as any,
          {
            rel: 'dns-prefetch',
            href: 'https://decathlonproqa.vteximg.com.br',
          } as any,
          {
            rel: 'dns-prefetch',
            href: 'https://decathlonstore.vtexassets.com',
          } as any,
        ]}
      />

      <SROnly as="h1" text={title} />
      <div className="flex items-center max-w-[1280px] w-full my-0 mx-auto px-4 mb-6">
        <Breadcrumb
          type="single"
          breadcrumbList={[
            {
              item: `/pesquisa?q=${searchParams.term}`,
              name: searchParams.term ?? '',
              position: 1,
            },
          ]}
        />
      </div>

      <ProductGalleryV2
        title={searchParams.term ?? ''}
        searchEngine="vtex"
        salesChannel={isB2B ? B2B.salesChannel : B2C.salesChannel}
        type="search"
        pageType="search"
      />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query SearchPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

export const getServerData = async (props: any) => {
  const { query } = props
  const searchTerm = query.q

  try {
    const { data } = await axios.get(
      'https://decathlonstore.myvtex.com/_v/api/intelligent-search/product_search',
      {
        params: {
          query: searchTerm,
        },
      }
    )

    return {
      status: 200,
      props: {
        redirect: data?.redirect ?? null,
      },
    }
  } catch (err) {
    console.error(err)

    return {
      status: 500,
      props: {},
      headers: {
        'cache-control': 's-maxage=31536000, stale-while-revalidate',
      },
    }
  }
}
